import React from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
} from 'react-admin';
import { enumToArray, SoccerData, Storage } from 'ultimate-league-common';
import { StorageImageInput } from '~business/storage/StorageImageInput';
import { uploadToStorage } from '~business/storage/service';
import { convertAsset, IAssetValue } from '~technical/storage';

const typeChoices = enumToArray(SoccerData.Competition.CompetitionType).map(
  (type) => ({
    id: type,
    name: type,
  })
);

const CompetitionForm = (props: SimpleFormProps) => {
  const { save } = props;

  const handleSave = async (
    values: { id: string; asset: IAssetValue },
    redirect: string,
    ...params: any[]
  ) => {
    const asset = await (async () => {
      if (!values.asset?.medium) {
        return {
          small: null,
          medium: null,
        };
      }

      if (typeof values.asset.medium === 'string') {
        return {
          small: values.asset.small!,
          medium: values.asset.medium,
        };
      }

      return {
        small: await uploadToStorage(
          {
            type: Storage.StorageType.PUBLIC_COMPETITION_ASSET,
            competitionId: values.id,
            format: Storage.AssetFormat.SMALL,
          },
          await convertAsset(values.asset.medium.rawFile)
        ),
        medium: await uploadToStorage(
          {
            type: Storage.StorageType.PUBLIC_COMPETITION_ASSET,
            competitionId: values.id,
            format: Storage.AssetFormat.MEDIUM,
          },
          values.asset.medium.rawFile
        ),
        mint: await uploadToStorage(
          {
            type: Storage.StorageType.TOOL,
          },
          values.asset.medium.rawFile
        ),
      };
    })();

    return save(
      {
        ...values,
        asset,
      },
      redirect,
      ...params
    );
  };

  return (
    <SimpleForm {...props} save={handleSave}>
      <TextInput source="idOriginal" />
      <TextInput source="name" />
      <TextInput source="nameOriginal" disabled />
      <SelectInput source="type" choices={typeChoices} />
      <StorageImageInput source="asset.medium" label="Asset" />
      <BooleanInput source="covered" />
    </SimpleForm>
  );
};

export const CompetitionEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <CompetitionForm />
    }
  </Edit>
);
